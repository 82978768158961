// Required for native package, the use of window prevents export native
( function( window ) {

  if ( window.Package ) {

    N = {};

  } else {

    window.N = {};
  }
} )( window );

// AMD
if ( typeof define === 'function' && define.amd ) {

  define( 'N', [], function() {

    return N;
  } );
} else if ( typeof exports !== 'undefined' && !exports.nodeType ) {
  // Common JS

  if ( typeof module !== 'undefined' && !module.nodeType && module.exports ) {

    exports = module.exports = N;
  }

  exports.default = N;
}

// jquery ajax 
N.ajax = function( url, data, callback ) {

  // start preloader
  $( 'app-preloader div' ).removeClass( 'none' ).addClass( 'active' );

  // request
  $.ajax( {

    url: url,
    type: 'post',
    data: data,
    cache: true,
    datatype: 'json',
    success: function( data ) {

      // check error
      if ( data.code ) return alert( data.message );

      // set callback
      callback();
    },
    complete: function() {

      // stop preloader
      $( 'app-preloader div' ).addClass( 'none' ).removeClass( 'active' );
    }
  } );
}

// server
N.ios = function( os ) {

  // check ios
  if ( os && os.toLowerCase().indexOf( 'i' ) > -1 ) return true;

  return /com.thestarasia.ios/i.test( navigator.userAgent.toLowerCase() );
}

N.native = function( force ) {

  if ( force ) return true;

  return /com.thestarasia/i.test( navigator.userAgent.toLowerCase() );
}

N.social = function( email ) {

  var data = {

    email: email
  }

  N.ajax( '/sign/getSocial', data, function() {

    // do nothing
  } );
}

N.google = function( token, os ) {

  var data = {

    idToken: token
  }

  N.ajax( '/sign/setGoogle', data, function() {

    // set cookie
    N.saveCookie( os );
  } );
}

N.twitter = function( token, os ) {

  var data = {

    idToken: token
  }

  N.ajax( '/sign/setTwitter', data, function() {

    // set cookie
    N.saveCookie( os );
  } );
}

N.facebook = function( token, os ) {

  var data = {

    idToken: token
  }

  N.ajax( '/sign/setFacebook', data, function() {

    // set cookie
    N.saveCookie( os );
  } );
}

// client
N.saveCookie = function( os ) {

  try {

    // check platform
    if ( N.ios( os ) ) {

      setTimeout( function() {

        window.location.href = 'thestarasia://saveCookie';

      }, 0 );
    } else {

      window.thestarasia.saveCookie();
    }

    setTimeout( function() {

      return window.location.href = '/';

    }, 500 );

  } catch ( error ) {}
}

N.loginGoogle = function() {

  try {

    // check platform
    if ( N.ios() ) {

      return window.location.href = 'thestarasia://loginGoogle';
    }

    return window.thestarasia.loginGoogle();

  } catch ( error ) {}
}

N.loginTwitter = function() {

  try {

    // check platform
    if ( N.ios() ) {

      return window.location.href = 'thestarasia://loginTwitter';
    }

    return window.thestarasia.loginTwitter();

  } catch ( error ) {}
}

N.loginFacebook = function() {

  try {

    // check platform
    if ( N.ios() ) {

      return window.location.href = 'thestarasia://loginFacebook';
    }

    return window.thestarasia.loginFacebook();

  } catch ( error ) {}
}

N.copyClipboard = function( text ) {

  try {

    // check platform
    if ( N.ios() ) {

      return window.location.href = 'thestarasia://copyClipboard?text=' + text;
    }

    return window.thestarasia.copyClipboard( text );

  } catch ( error ) {}
}

N.logoutFirebase = function() {

  try {

    // check platform
    if ( N.ios() ) {

      return window.location.href = 'thestarasia://logoutFirebase';
    }

    return window.thestarasia.logoutFirebase();

  } catch ( error ) {}
}

N.callInappBrowser = function( url ) {

  try {

    // check platform
    if ( N.ios() ) {

      return window.location.href = 'thestarasia://callInappBrowser?url=' + url;

    } else {

      return window.thestarasia.callInappBrowser( url );

    }
  } catch ( error ) {}
}

N.callExternalBrowser = function( url ) {

  try {

    // check platform
    if ( N.ios() ) {

      return window.location.href = 'thestarasia://callExternalBrowser?url=' + url;

    } else {

      return window.thestarasia.callExternalBrowser( url );

    }
  } catch ( error ) {}
}

N.clearCacheAndCookie = function() {

  try {

    // check platform
    if ( N.ios() ) {

      return window.location.href = 'thestarasia://clearCacheAndCookie';
    }

    return window.thestarasia.clearCacheAndCookie();

  } catch ( error ) {}
}